import React, { useState, useEffect } from "react";
const artworksData = [
	{
	  id: 1,
	  imageUrl: 'img/bitshape1.webp',
	},
	{
	  id: 2,
	  imageUrl: 'img/bitshape2.webp',
	},
    ];
    

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={artwork.imageUrl} alt="Artwork" className="artworksimg-bitshape" />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Bitshape</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
        図形はビットコイン価格の変動に応じて変化し、描かれる。
        変動率が大きいほど図の変化は激くなり、線が重なりながら描かれていく。
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
        Data Visualization Works.<br />
        The figure changes and is drawn in response to bitcoin price fluctuations.<br />
        The larger the rate of fluctuation, the more violent the change in the figure.
        </p>
        <br />
        <br />
        <iframe
          src="https://openprocessing.org/sketch/1607535/embed/?plusEmbedHash=5d48c060&userID=323167&plusEmbedTitle=true&show=sketch"
          class = "bitshape"
          title="Bitshape Sketch"
        ></iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
        </p>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
