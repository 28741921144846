import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear(); // 現在の年を取得

  return (
    <footer>
      <p>&copy; {currentYear} RintaroFujita</p>
    </footer>
  );
}

export default Footer;
