import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/technosomen1.webp",
  },
  {
    id: 2,
    imageUrl: "img/technosomen2.webp",
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };


  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={artwork.imageUrl} alt="Artwork" className="artworksimg" />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">TechnoSOMEN</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
          日本の夏といえばそうめんを連想するのではないでしょうか。『TechnoSOMEN』は更にそうめんを光と音で演出します。
          音と光で包まれている中で、そうめんを食べることで、より一層夏の風物詩を楽しむことができます。
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
          When you think of summer in Japan, you probably associate it with
          Soumen (fine white noodles). TechnoSOMEN" will further enhance the
          somen noodle experience with light and sound. By eating Soumen
          surrounded by sound and light, you can enjoy the summer season even
          more.
        </p>
        <br />
        <br />
        <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/EcDl_gWTRIc?si=h53B6m8WCWSRGklT"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
          <a href="https://www.instagram.com/yuki01_0w0/">Yuki Hirayama(Creator)</a>
          <br />
          <a href="https://x.com/hoehoe">hoehoe(Creator)</a>
          <br />
          <a href="https://www.instagram.com/ktysok/">Kota Yoshioka(Idea)</a>
        </p>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
