import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/recognition1.webp",
  },
  {
    id: 2,
    imageUrl: "img/recognition2.webp",
  },
  {
    id: 3,
    imageUrl: "img/recognition3.webp",
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img
              src={artwork.imageUrl}
              alt="Artwork"
              className="artworksimg-technoandon"
            />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Recognition: capture(scene)</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
          パノラマ写真は、1840年代に考案されたスイングレンズ方式のカメラを起源とし、スマートフォンの普及に伴い、現在では身近なものとなった。
          通常のパノラマ写真は、人がカメラを手で動かしながら撮影し、細長い1枚の画像を生成する。しかし、この作品ではiPhoneを固定したまま撮影している。
          iPhoneの前にあるディスプレイでは画像が単に右から左に流れているだけだが、iPhone越しに見るとパノラマ写真が撮影されている。撮影後に表示される結果は、
          鑑賞者が実際に見ているものとパノラマ画像との間に「ずれ」を生じさせる。
          iPhoneには特別な技術的操作は加えておらず、ディスプレイ上に再生される映像は、iOS特有の撮影アルゴリズムとスリットスキャンを応用し、
          スキャンのタイミングを調整することで撮影が可能になっている。この作品は、鑑賞者が普段何気なく使っている機能を誤用し、作品を通じて新たな視覚体験やものの見方を促す試みである。
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
          Panoramic photography originated from the swing lens camera invented
          in the 1840s, and has become familiar today with the spread of
          smartphones. A normal panoramic photograph is taken by moving the
          camera by hand, generating a single long and narrow image. However, in
          this work, the iPhone is fixed in place. On the display in front of
          the iPhone, the image simply flows from right to left, but when viewed
          through the iPhone, a panoramic photograph is taken. The result
          displayed after shooting creates a "gap" between what the viewer
          actually sees and the panoramic image. No special technical operations
          were performed on the iPhone, and the image played on the display was
          taken by applying iOS-specific shooting algorithms and slit scanning,
          and adjusting the timing of the scan. This work is an attempt to
          misuse functions that viewers usually use casually, and encourage new
          visual experiences and ways of looking at things through the work.
        </p>
        <br />
        <br />
        <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/jZeSh5alXvM?si=o8OYX7RXchYblfUO"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-med
        ia; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
          <a href="https://scottallen.ws/biography">Scott Allen(Advisor)</a>
          <br />
        </p>
        <br />
        <section>
          <h2 className="section">Conference / Exhibition </h2>
          <ul>
            <li>
              <a
                href="https://asia.siggraph.org/2024/presentation/?id=artg_248&sess=sess183"
                target="_blank"
                rel="noopener noreferrer"
              >
                SIGGRAPH Asia 2024 Art Gallery
              </a>
            </li>
            <br/>
            <li>
              <a
                href="https://hack.wired.jp/finalists/index.html"
                target="_blank"
                rel="noopener noreferrer"
              >
                WIRED CREATIVE HACK AWARD 2024 Finalist
              </a>
            </li>
            <br/>
            <li>
            <a
              href="https://peatix.com/event/4210013"
              target="_blank"
              rel="noopener noreferrer"
            >
              HOMEWORKS 2024
            </a>
          </li>
          </ul>
        </section>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
