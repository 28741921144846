import React, { useState, useEffect, useRef } from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import * as THREE from "three";

import Header from "./components/Header";
import Main from "./components/Main";
import Footer from "./components/Footer";
import Loading from "./components/Loading";
import Decieve from "./pages/decieve";
import PulsatioLitoris from "./pages/ebbs_and_flows";
import Hackognition from "./pages/hackognition";
import portfolio from "./pages/portfolio";
import tongueprotecter from "./pages/tongue_protecter";
import technosomen from "./pages/technosomen";
import bitshape from "./pages/bitshape";
import technoandon from "./pages/technoandon";
import ifsmilecapture from "./pages/ifsmilecapture";
import recognitoncapturescrne from "./pages/reognition_capture_scene.js";
import "../src/App.css";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [model, setModel] = useState(null);
  const canvasRef = useRef(null); // Reference to the canvas element

  useEffect(() => {
    const loadSTLModel = async () => {
      const loader = new STLLoader();
      loader.load(
        "/models/rintaroface2.stl", // Replace with your STL file path
        (geometry) => {
          setModel(geometry);
          setIsLoading(false);
        },
        undefined,
        (error) => {
          console.error("Error loading STL model:", error);
        }
      );
    };

    loadSTLModel();
  }, []);

  useEffect(() => {
    if (model && canvasRef.current) {
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current });
      renderer.setSize(window.innerWidth, window.innerHeight);

      const material = new THREE.MeshNormalMaterial();
      const mesh = new THREE.Mesh(model, material);
      scene.add(mesh);

      camera.position.z = 5;

      const animate = () => {
        requestAnimationFrame(animate);
        mesh.rotation.x += 0.01;
        mesh.rotation.y += 0.01;
        renderer.render(scene, camera);
      };

      animate(); // Start animation
    }
  }, [model]);

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        <HashRouter>
          <Header />
          <Switch>
            <Route exact path="/" component={Main} />
            <Route path="/decieve" component={Decieve} />
            <Route path="/pulsatiolitoris" component={PulsatioLitoris} />
            <Route path="/hackognition" component={Hackognition} />
            <Route path="/portfolio" component={portfolio} />
            <Route path="/tongueprotecter" component={tongueprotecter} />
            <Route path="/technosomen" component={technosomen} />
            <Route path="/bitshape" component={bitshape} />
            <Route path="/technoandon" component={technoandon} />
            <Route path="/recognitioncapturescene" component={recognitoncapturescrne} />
            <Route path="/ifsmilecapture" component={ifsmilecapture} />
          </Switch>
          <Footer />
        </HashRouter>
      )}
    </div>
  );
}

export default App;
