import React, { useState, useEffect } from "react";

const artworksData = [
  {
    id: 1,
    imageUrl: "img/tongue.webp",
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };


  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? "active" : ""}`}
          >
            <img src={artwork.imageUrl} alt="Artwork" className="artworksimg" />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Tongue Protecter</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
          猫舌の人のために、熱い飲み物を飲むときに適温で飲むことができる装置です。
          飲み物の種類を登録することで過去のログから飲み物が冷めるまでの平均値を出し、最適な温度になるまで蓋をして冷ましてくれます。
        </p>
        <br />
        <hr></hr>
        <br />
        <p className="artworksdetailen">
          For those who do not like hot drinks, this device allows you to drink
          hot drinks at the proper temperature. By registering the type of
          drink, the device averages out the average temperature until the drink
          cools down from the past logs, and cools the drink with the lid on
          until it reaches the optimum temperature.
        </p>
        <br />
        <br />
        <iframe
          width="350px"
          height="500"
          src="https://www.youtube.com/embed/LlY73E8fNbU?si=GSSf8IchBU7DEaYl"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
          className="responsive-iframe"
        ></iframe>
        <p className="credit">
          Credit:
          <br />
          <a href="/">Rintaro Fujita(Creator)</a>
          <br />
          <a href="/">Yuki Hirayama(Creator)</a>
          <br />
        </p>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
