import React from 'react';
import { Link } from "react-router-dom"; 
function Header() {
  return (
    <header>
      <Link to='/' className='link' ><h1>RintaroFujita Portfolio</h1></Link>
    </header>
  );
}


export default Header;
