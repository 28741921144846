import React, { useState, useEffect } from 'react';

const artworksData = [
  {
    id: 1,
    imageUrl: 'img/ebbs_and_flow1.webp',
  },
  {
    id: 2,
    imageUrl: 'img/ebbs_and_flow2.webp',
  },
  {
    id: 3,
    imageUrl: 'img/ebbs_and_flow3.webp',
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };


  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
          >
            <img src={artwork.imageUrl} alt="Artwork" className="artworksimg" />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Pulsatio Litoris</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
	 電力を一切必要とせず、自然と調和しながら潮の高さを測定する装置。砂浜に刺さった木の棒が、波が砂浜に押し寄せる力によって外れ、その位置に応じて潮位を捉える。
	 この木の棒たちは等間隔に配置され、波の力強さや日々の潮の変動を感知し、私たちに海の律動を紡ぎ出す。
        </p>
        <br />
		<hr></hr>
        <br />
        <p className="artworksdetailen">
	  This device measures the height of the tide without requiring any electricity and in harmony with nature. Wooden sticks stuck in the beach are dislodged by the force of waves crashing on the beach, and the tidal height is measured according to their position.
These wooden sticks are placed at equal intervals to sense the force of the waves and the daily fluctuations of the tides, and to weave the rhythms of the ocean for us.
	  </p>
        <br />
        <br />
        <iframe width="350px" height="500" src="https://www.youtube-nocookie.com/embed/YHBaLPQ7aXY?si=Byo7hCTN87HH486v" title="YouTube vid
        eo player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen className="responsive-iframe"></iframe>
        <p className="credit">
          Credit:<br />
          <a href="/">Rintaro Fujita(Creator)</a><br />
          <a href="https://scottallen.ws/biography" target="_blank" rel="noopener noreferrer">Scott Allen(Advisor)</a>
        </p>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
