import React, { useState, useEffect } from 'react';

const artworksData = [
  {
    id: 1,
    imageUrl: 'img/decieve1.webp',
  },
  {
    id: 2,
    imageUrl: 'img/decieve2.webp',
  },
  {
    id: 3,
    imageUrl: 'img/decieve3.webp',
  },
];

function Decieve() {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      nextSlide();
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [currentSlide]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % artworksData.length);
  };

  return (
    <div className="slideshow-container">
      <br />
      <div className="slideshow">
        {artworksData.map((artwork, index) => (
          <div
            key={artwork.id}
            className={`slide ${index === currentSlide ? 'active' : ''}`}
          >
            <img src={artwork.imageUrl} alt="Artwork" className="artworksimg" />
          </div>
        ))}
      </div>
      <h2 className="artworkstitle">Deceive</h2>
      <br />
      <br />
      <div className="artworkscontainer">
        <p className="artworksdetail">
		iPhoneを囲むように設置されたスピーカーから流れる音に対するSiriの反応を可視化したサウンド・インスタレーション。
		この作品は、沖縄・瀬底島方言の音声データベースを元に制作され、ハイパスフィルターや音声ブレンドなどの加工を施した。
		Siriを騙し、本来の発音ではないものの、特定の操作を実現できるようにし、その音声はSiriだけではなく聞くものをも欺く。
        </p>
        <br />
		<hr></hr>
        <br />
        <p className="artworksdetailen">
		A sound installation that visualizes Siri's response to the sounds coming from the speakers surrounding the iPhone.
		Using a database of sounds in the Sesoko Island dialect of Okinawa, high-pass filtering and voice blending were used to process the sounds.
		By tricking Siri, the voice deceives not only Siri but also those who listen to it by enabling them to realize certain operations, although they are not pronounced as they should be.
		</p>
        <br />
        <br />
        <iframe width="350px" height="500" src="https://www.youtube.com/embed/P5jX_p1ETBk?si=6cCDImfL9e684k5I" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-med
        ia; gyroscope; picture-in-picture; web-share" allowfullscreen className="responsive-iframe">
        </iframe>
        <p className="credit">
          Credit:<br />
          <a href="/">Rintaro Fujita(Creator)</a><br />
          <a href="https://scottallen.ws/biography" target="_blank" rel="noopener noreferrer">Scott Allen(Advisor)</a>
        </p>
        <br />
        <section>
        <h2 className="section">Conference / Exhibition </h2>
        <ul>
          <li>
            <a
              href="https://media.kyoto-seika.ac.jp/latentmedia/exhibition1h2023/"
              target="_blank"
              rel="noopener noreferrer"
            >
              LatentMediaLab.2023 1H "灯火"
            </a>
          </li>
        </ul>
      </section>
        <br />
        <br />
      </div>
      <br />
      <br />
      <br />
    </div>
  );
}

export default Decieve;
