import React, { useState } from "react";

function Decieve() {
  const [password, setPassword] = useState("");
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(false);

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const submitPassword = (e) => {
    e.preventDefault();

    if (password === 'rintarofujitasite625') { //見たな！
      setIsPasswordCorrect(true);
    } else {
      alert('Incorrect password. Please try again.');
    }
  };

  return (
    <div className="slideshow-container">
      {isPasswordCorrect ? (
        <>
          <h2>Portfolio集</h2>
          <ul>
            <li><a href="https://example.com/link1">Link 1</a></li>
            <li><a href="https://example.com/link2">Link 2</a></li>
            <li><a href="https://example.com/link3">Link 3</a></li>
          </ul>

        </>
      ) : (
        <form className="password-prompt" onSubmit={submitPassword}>
          <label>Password: </label>
          <input
            type="password"
            value={password}
            onChange={handlePasswordChange}
          />
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
}

export default Decieve;